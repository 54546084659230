// import React from 'react';
// import logo from './logo.svg';
// import { Link } from 'react-router-dom'
// import './App.css';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Report from "./components/report.component";
import Welcome from "./components/welcome.component";
import RenderOnAnonymous from "./components/keycloak-components/render-on-anonymous.component";
import RenderOnAuthenticated from "./components/keycloak-components/render-on-authenticated.component";

const takTheme = createTheme({
  palette: {
    primary: {
      main: "#41B6AD",
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          color: "#fff",
        },
      },
    },
  },
});

function App() {
  return (
    // <ThemeProvider theme={takTheme}>
      <BrowserRouter>
        <div className="App">
          <RenderOnAnonymous>
            <Welcome />
          </RenderOnAnonymous>
          <RenderOnAuthenticated>
            <Routes>
              <Route path="/" element={<Report open={true} />} />
              <Route path="/report" element={<Report open={true} />} />
              {/* <Route path="/report-list" element={<ReportParameters />} /> */}
            </Routes>
          </RenderOnAuthenticated>
        </div>
      </BrowserRouter>
    // </ThemeProvider>
  );
}

export default App;
