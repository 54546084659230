import React from 'react';
import axios from 'axios';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import UserService from "./keycloak-services/user-service";

import "./i18n"

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// const renderApp = root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

axios.interceptors.request.use(config => {
  if (UserService.isLoggedIn()) {
    const cb = () => {
      config.headers.Authorization = `Bearer ${UserService.getToken()}`;
      return Promise.resolve(config);
    };
    return UserService.updateToken(cb);
  }
});

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  UserService.doLogout();
  return Promise.reject(error);
});

const renderApp = () => ReactDOM.render(<App />, document.getElementById("root"));

UserService.initKeycloak(renderApp);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
