import React, { useState, useEffect } from 'react';

import { FormControl, FormLabel, RadioGroup, Radio, FormControlLabel, TextField, Button, Box, Grid, Autocomplete } from '@mui/material';

import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import dayjs from 'dayjs';

import MenuAppBar from './menu-app-bar.component';
import Footer from './footer.component';
import LoadSpinner from './util/load-spinner.component';

interface Props {
  open: boolean;
}

interface FormValues {
  from_date: any,
  to_date: any,
  reportId: string,
  company: string
}

const Report = (props: Props) => {
  const { t } = useTranslation();

  const [reportList, setReportList] = useState<any[]>([]);
  const { handleSubmit, control, setValue } = useForm<FormValues>();

  useEffect(() => {
    setValue('company', 'tgi');

    let today = new Date();
    setValue('from_date', dayjs(new Date(today.getFullYear(), 0, 1)));
    setValue('to_date', dayjs(new Date(today.getFullYear(), today.getMonth(), 0)));

    const url = process.env.REACT_APP_API_PROTOCOL + '://' + process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + '/reporting/api/report/list';

    axios.get(url, {
    })
      .then((response) => {
        setReportList(response.data);
        // localize the report names
        for (const report of response.data) {
          report.label = t(report.id);
        }
      });
  }, [setValue]);

  const formatDate = (date: any) => {
    return date.$d.getFullYear() + '-' + ('0' + (date.$d.getMonth() + 1)).slice(-2) + '-' + ('0' + date.$d.getDate()).slice(-2);
  }

  /* funzioni per gestione loader */
  const [open, setOpen] = React.useState(false);
  const showLoader = (value: any) => {
    setOpen(value);
  };

  const myHandleSubmit = (data: any) => {
    showLoader(true);

    const url = process.env.REACT_APP_API_PROTOCOL + '://' + process.env.REACT_APP_API_HOST + ':' + process.env.REACT_APP_API_PORT + '/reporting/api/report/' + data.reportId + '/' + data.company.toUpperCase();

    axios.get(url, {
      responseType: 'blob',
      params: {
        from_date: formatDate(data.from_date),
        to_date: formatDate(data.to_date)
      }
    })
      .then((response) => {

        const filename = formatDate(data.from_date).replaceAll('-', '.') + '-' + formatDate(data.to_date).replaceAll('-', '.') + '_' + data.company.toUpperCase() + '-' + data.reportId + '.xlsx';

        const href = URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        showLoader(false);
      }).catch((error) => {
        console.log(error)
        showLoader(false);
      });
  };

  const border = 0;

  return (
    <>
      <div>
        <LoadSpinner open={open} />
      </div>
      <MenuAppBar />
      <div style={{ marginTop: 80 }}>
        <form onSubmit={handleSubmit((data) => {
          myHandleSubmit(data);
        })}>
          <Box display="flex"
            justifyContent="center"
          >
            <Grid container sx={{ width: '60%' }} spacing={2} border={border} direction='row'>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start" }} border={border}>
                <FormLabel>{t("dateTimerange")}</FormLabel>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "flex-start" }} border={border}>
                <FormControl>
                  <Controller
                    name='from_date'
                    rules={{
                      required: "required"
                    }}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      const { onChange, value } = field;
                      return (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label={t("fromDate")}
                              value={value ? value : null}
                              onChange={onChange}
                            />
                            {error ? <span style={{ color: 'red' }}>{error.message}</span> : null}
                          </LocalizationProvider>
                        </>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "flex-start" }} border={border}>
                <FormControl>
                  <Controller
                    name='to_date'
                    rules={{
                      required: "required"
                    }}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      const { onChange, value } = field;
                      return (
                        <>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              label={t("toDate")}
                              value={value ? value : null}
                              onChange={onChange}
                            />
                            {error ? <span style={{ color: 'red' }}>{error.message}</span> : null}
                          </LocalizationProvider>
                        </>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start" }} border={border}>
                <FormLabel>{t("company")}</FormLabel>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start" }} border={border}>
                <FormControl>
                  <Controller
                    name='company'
                    rules={{
                      required: "required"
                    }}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      const { onChange, value } = field;
                      return (
                        <>
                          <RadioGroup
                            name="company"
                            value={value ? value : null}
                            onChange={onChange}
                            row
                          >
                            <FormControlLabel
                              key="tgi"
                              value="tgi"
                              control={<Radio size="small" />}
                              label="Tech Gap Italia"
                            />
                            <FormControlLabel
                              key="ank"
                              value="ank"
                              control={<Radio size="small" />}
                              label="Anoki"
                            />
                            <FormControlLabel
                              key="koor"
                              value="koor"
                              control={<Radio size="small" />}
                              label="Koor"
                            />
                            <FormControlLabel
                              key="tgs srl"
                              value="tgs srl"
                              control={<Radio size="small" />}
                              label="TGS Srl"
                            />
                          </RadioGroup>
                          {error ? <span style={{ color: 'red' }}>{error.message}</span> : null}
                        </>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start" }} border={border}>
                <FormLabel>{t("reportType")}</FormLabel>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "flex-start" }} border={border}>
                <FormControl style={{ display: "flex", width: "100%" }}>
                  <Controller
                    name='reportId'
                    rules={{
                      required: "required"
                    }}
                    control={control}
                    render={({ field, fieldState: { error } }) => {
                      const { onChange, value, ref, } = field;
                      return (
                        <>
                          <Autocomplete style={{ display: "flex", width: "100%" }}
                            options={reportList}
                            sx={{ width: 300 }}
                            value={value ? reportList.find((option) => {
                              return value === option.id;
                            }) ?? null : null}
                            onChange={(event: any, newValue) => {
                              onChange(newValue ? newValue.id : null);
                            }}
                            renderInput={(params) => <TextField {...params} label={t("report")} inputRef={ref} />}
                          />
                          {error ? <span style={{ color: 'red' }}>{error.message}</span> : null}
                        </>
                      )
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ display: "flex", justifyContent: "center" }} border={border}>
                <Button variant="outlined" color="primary" type="submit">
                  {t("submit")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </form>
      </div>
      <Footer />
    </>
  )

}

export default Report;