import React from 'react';

import {
  Backdrop,
  CircularProgress
} from '@mui/material';

class LoadSpinner extends React.Component<{open: boolean}, {}> {
  render() {
    return (
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={this.props.open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
}

export default LoadSpinner;