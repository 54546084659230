import { FC, ReactElement, useState, useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import axios from "axios";

const packageJson = require('../../package.json');

export const Footer: FC = (): ReactElement => {
  const url = process.env.REACT_APP_API_PROTOCOL + "://" + process.env.REACT_APP_API_HOST +  ":" + process.env.REACT_APP_API_PORT +  "/reporting/api/version";
	
	const [version, setVersion] = useState<string>();

  useEffect(() => {
    axios.get(url, {}).then((response) => {
			setVersion(response.data.version);
    });
  }, [url]);

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        height: "auto",
        backgroundColor: "primary.main",
        color: "white",
        padding: "7px",
      }}
    >
      <Container maxWidth="lg">
        <Grid container direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="subtitle1">
							<b>ver.:</b> app <b>{ packageJson.version }</b> <b>|</b> api <b>{ version }</b>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
