import UserService from "../keycloak-services/user-service";
import MenuAppBar from "./menu-app-bar.component";
import { Button, Typography, Box, Grid } from "@mui/material";

import { useTranslation } from "react-i18next";

const border = 0;

const Welcome = () => {
  const { t } = useTranslation();

  return (
    <>
      <MenuAppBar />
      <Box display="flex" justifyContent="center" style={{ marginTop: 40 }}>
        <Grid
          container
          sx={{ width: "60%" }}
          spacing={2}
          border={border}
          direction="row"
        >
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-start" }}
            border={border}
          >
          <Typography
            variant="h3"
            align="center"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {t("title")}
          </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
            border={border}
          >
          <img
        src='/totem-reporting.png'
        loading="lazy"
        width={300}
      />
      </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-start" }}
            border={border}
          >
          <Typography
            variant="h5"
            align="center"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {t("welcomeMessage1")}
          </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "flex-start" }}
            border={border}
          >
          <Typography
            variant="h6"
            align="center"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {t("welcomeMessage2")}
          </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
            border={border}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={() => UserService.doLogin()}
            >
              {t("login")}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Welcome;
